body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: rgb(92, 162, 40);
}

#sq_735 {
  width: 520px;
}

#sq_740 {
  width: 200px;
}

canvas {
  border: 1px solid black;
}

.svd_container .btn {
  background-color: #5ca228 !important;
  color: white !important;
}

.use-bootstrap {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
}

.use-bootstrap .sv_main {
  background-color: #f4f4f4;
}


.use-bootstrap .sv_main input[type="button"], .sv_main button {
  background-color: #5ca228 !important;
  color: white !important;
}

.use-bootstrap .btn:not(:disabled):not(.disabled) {
  min-height: 40px !important;
  min-width: 190px !important;
  font-size: 1.7em !important;
  border-radius: 8px;
}

.use-bootstrap .sv_main .sv_body {
  border-top: 2px solid #5ca228 !important;
}

.use-bootstrap .panel-footer, .use-bootstrap .card-footer {
  text-align: end;
}

.use-bootstrap .card-footer {
  background-color: #f4f4f4!important;
}

.use-bootstrap .sv_p_title {
  max-width: 90%;
}

.use-bootstrap .sv_custom_header {
  background-color: #f4f4f4!important;
}

.use-bootstrap .form-inline {
  padding: 10px 5px;
}
