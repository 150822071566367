.backgroundWrapper {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: rgba(0,0,0,0.7);
}
.wrapper {
  display: block;
  position: fixed;
  top: 50%;
  margin-top: -100px;
  left: 40%;
  margin-left:-300px;
}

.modalWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(123, 124, 126);
  font-size: 14pt;
  position: relative;
  margin: 0 auto;
  width: 800px;
  height: 220px;
  border: 2px solid black;
  background-color: white;
  padding-top: 20px;
}

.description {
  margin: 20px 60px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 10px 0;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 15px;
  font-family: sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  color: rgba(0, 0, 0, 0.54);
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  text-decoration: none;
}

.closeButton:focus, .closeButton:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .75;
}

.validationButton {
  position: absolute;
  left: 5%;
  bottom: 10px;

  font-size: 1.7em !important;
  min-height: 48px;
  border-radius: 8px !important;
}
